var screenVw = jQuery(window).width();
var screenVh = jQuery(window).height();
var screenHalf = screenVh + 600;
var mobileBreakpoint = 1023;
var lazyloadOptions = {
	elements_selector: '.lazy, .image-bg-lazy',
	threshold: 300,
	class_loading: 'lazyload-loading',
	class_loaded: 'lazyload-loaded'
};
var lazyLoad;

whatInput = whatInput.ask() // returns `mouse`, `keyboard` or `touch`
console.log(whatInput);

jQuery(document).ready(function($){
	alert("hello");
});

